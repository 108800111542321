<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <!-- <el-form :inline="true">
                <el-form-item label="unionid：">
                    <el-input v-model="unionid" placeholder="请输入uid"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form> -->
            <el-button type="primary" size="mini" @click="dialog = true">添加</el-button>
        </div>
        <el-table
            v-loading="loading"
            :data="blackList"
            border>
            <el-table-column
                fixed
                prop="id"
                label="编号"
                width="100"
            >
            </el-table-column>
            <el-table-column
                fixed
                prop="account"
                label="账号"
                width="250"
            >
            </el-table-column>
            <el-table-column
                label="账号类型"
                width="250"
            >
            <template slot-scope="scope">
                <div v-if="scope.row.account_type == 1">
                支付宝
                </div>
                <div v-if="scope.row.account_type == 2">
                微信
                </div>
                <div v-if="scope.row.account_type == 3">
                    用户ID
                </div>
            </template>
            </el-table-column>
<el-table-column label="创建时间">
    <template slot-scope="scope">
        {{scope.row.create_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="更新时间">
    <template slot-scope="scope">
        {{scope.row.update_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
        <el-button type="danger" size="mini" @click="del(scope.row.id)">删除</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<el-dialog title="修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="账号">
            <el-input v-model="account" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="类型">
            <el-radio-group v-model="account_type">
                <el-radio :label="1">支付宝</el-radio>
                <el-radio :label="2">微信</el-radio>
                <el-radio :label="3">用户ID</el-radio>
            </el-radio-group>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
    </div>
</el-dialog>

</section>
<!-- /.content -->
</template>

<script>
    import * as api from '@/config/api'
    export default {
        name: 'blackList',
        components: {},

        data() {
            return {
                blackList: [],
                total: 0,
                page: 1,
                pagesize: 10,

                dialog: false,
                account: '',
                account_type: '',
                loading: true
            }
        },
        watch: {},
        filters: {
            status(val) {
                let common = JSON.parse(window.localStorage.getItem('common'))
                let name = ''
                if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
                    common.order_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getBlackList()
        },
        methods: {
            close() {
                this.show = false
                this.getBlackList()
            },
            getBlackList(page, size) {
                this.loading = true
                api.getBlackList({
                    // uid: this.uid,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.blackList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getBlackList()
            },
            currentChange(val) {
                this.page = val
                this.getBlackList()
            },
            seach(page, size) {
                this.getBlackList(page, size)
            },
            del(id) {
                this.$confirm('请确定是否删除该黑名单', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delBlack({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.getBlackList()
                    })
                })
            },
            add() {
                api.addBlack({
                    account: this.account,
                    account_type: this.account_type
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.dialog = false
                    this.getBlackList()
                })
            }
        }
    }
</script>
<style scoped>
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
</style>